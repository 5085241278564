import { UseQueryResult } from '@tanstack/react-query';
import { RouterOutput } from '@utils/trpc';
import { Control, FieldErrors, FieldValues, Path } from 'react-hook-form';
import { useError } from '~/hooks/useError';
import { BetterSelectInput, ControlledBetterSelectInput } from '../BetterSelectInput';

type BaseBusinessUserSelectProps = {
	getBusinessUserOpts: UseQueryResult<RouterOutput['users']['getBusinessUserOpts']>;
	id: string;
	ignoreUserIds?: number[];
	labelText?: string;
	placeholder?: string;
	testId?: string;
};

type ControlledBusinessUserSelectProps<TFieldValues extends FieldValues> = BaseBusinessUserSelectProps & {
	control: Control<TFieldValues>;
	errors?: FieldErrors<TFieldValues>;
	name: Path<TFieldValues>;
	onChange?: (userId: number) => void;
};

type UncontrolledBusinessUserSelectProps = BaseBusinessUserSelectProps & {
	defaultValue?: number | null;
	onChange?: (userId: number | null) => void;
};

export const UncontrolledBusinessUserSelect = ({
	defaultValue,
	getBusinessUserOpts,
	ignoreUserIds,
	id,
	labelText,
	onChange,
	placeholder,
	testId,
}: UncontrolledBusinessUserSelectProps) => {
	const allUsers = getBusinessUserOpts.data ?? [];
	const options = ignoreUserIds ? allUsers.filter((opt) => ignoreUserIds.indexOf(opt.value) == -1) : allUsers;

	return (
		<BetterSelectInput<number>
			canBeNull
			defaultValue={defaultValue ?? null}
			id={id}
			isLoading={getBusinessUserOpts.isLoading}
			labelText={labelText}
			onChange={onChange}
			options={options}
			placeholder={placeholder}
			testId={testId}
		/>
	);
};

export const BusinessUserSelect = <TFieldValues extends FieldValues>({
	control,
	errors,
	getBusinessUserOpts,
	id,
	ignoreUserIds,
	labelText,
	name,
	onChange,
	placeholder,
	testId,
}: ControlledBusinessUserSelectProps<TFieldValues>) => {
	const error = useError(name, errors ?? {});
	const allUsers = getBusinessUserOpts.data ?? [];
	const options = ignoreUserIds ? allUsers.filter((opt) => ignoreUserIds.indexOf(opt.value) == -1) : allUsers;

	return (
		<ControlledBetterSelectInput
			control={control}
			errorMessage={error?.message}
			id={id}
			isLoading={getBusinessUserOpts.isLoading}
			labelText={labelText}
			name={name}
			onChange={onChange}
			options={options}
			placeholder={placeholder}
			testId={testId}
		/>
	);
};
